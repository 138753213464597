/****************** Home section ******************/
#home {
  font-family: var(--font-family-style);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
}
.home-sec {
  width: 95%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
}
.home-sec-head {
  width: 100%;
}
.home-cont-hr {
  border: 2px solid rgba(128, 128, 128, 0.089);
  width: 98vw;
  margin-block: 1.2rem;
}
.home-pamplet-cont {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.home-pamplet-sub-cont {
  width: auto;
  display: flex;
  padding-inline: 6.5vw;
  gap: 1vw;
  transform: translateX(0);
  transition: 1s transform;
}
.pamplet-slide-btn {
  position: absolute;
  top: 45%;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.432);
  z-index: 1;
  background-color: white;
  color: black;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pamplet-slide-right-btn {
  right: 6.5vw;
}
.pamplet-slide-left-btn {
  left: 7.5vw;
}
.home-pamplet-img {
  aspect-ratio: 4/1;
  width: 87vw;
  /* aspect-ratio: 1.9/1;
  width: 41.5vw; */
  max-width: 87vw;
  border-radius: 10px;
}

.home-pamplet-dots {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2vw;
}
.home-pamplet-dots fieldset {
  border: none;
}
.home-pamplet-dot {
  width: 0.7rem;
}
.home-pamplet-slide-box {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}
/* home categories section */
.home-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-categories-cont-head {
  margin-bottom: 1rem;
  position: relative;
}
.home-categries-cont-main {
  margin-top: 0.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 2rem;
  column-gap: 5rem;
}
.home-categories-cont-main-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* home viewed recently */

.slide-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  /* transform: rotate(180deg); */
  top: 55%;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: #323232;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: rgb(192 191 191 / var(--tw-border-opacity));
  border-width: 1px;
}
.slide-btn:hover {
  scale: 1.1;
}
.home-viewed-recent-sec-next-btn {
  transform: rotate(0deg);
  right: 0;
}
.home-viewed-recent-sec-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.home-viewed-recent-sec-head-btn {
  border: none;
  border-bottom: 1px solid var(--color-blue);
  background-color: var(--color-white);
  font-size: var(--text-size-medium);
  color: var(--color-blue);
  font-weight: 600;
  cursor: pointer;
}

.home-viewed-recent-sec-main-item-pricef {
  font-weight: 600;
}

.home-viewed-recent-sec-main {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  gap: 1rem;
  scroll-behavior: smooth;
}
.home-viewed-recent-sec-main::-webkit-scrollbar {
  display: none;
}
.home-viewed-recent-sec-main-item {
  display: flex;
  /* gap: 0.5rem; */
  border: 1px solid rgba(0, 0, 0, 0.192);
  border-radius: 10px;
  height: 7rem;
  min-width: 20rem;
  cursor: pointer;
  padding: 0px;
  justify-content: space-between;
}
.home-viewed-recent-sec-item-i {
  /* width: 7rem; */
  height: 7rem;
  width: 7rem;
  border-radius: 10px;
}
.home-viewed-recent-sec-main-item-detail {
  display: flex;
  flex-direction: column;
  padding-block: 1rem;
  justify-content: space-around;
  padding-right: 16px;
  padding-left: 16px;
}
.home-viewed-recent-sec-main-item-sub-detail {
  display: flex;
  gap: 1rem;
}
.home-viewed-recent-sec-main-item-price {
  text-decoration: line-through;
  color: grey;
  font-size: var(--text-size-medium);
}
.home-viewed-recent-sec-main-item-detail-h4 {
  font-size: var(--text-size-medium);
  color: var(--text-color-grey);
}
.home-viewed-recent-sec-main-item-discount {
  font-size: var(--text-size-medium);
  color: var(--color-green);
}
/* home occassion pamplet */

.home-occasion-sec {
  display: grid;
  width: 95%;
  grid-template-columns: repeat(3, 1fr);
}
.home-occasion-sec img {
  width: 100%;
}
.home-occasion-sec-cont1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-occasion-sec-cont1-p {
  font-size: var(--text-size-ex-large);
  width: 20rem;
  color: var(--text-color-grey);
}

/* home decors by category sec */

.home-decor-by-cate-sec {
  gap: 0.7rem;
  letter-spacing: 0.2px;
}

#spotlight {
  /* background: linear-gradient(235.31deg, #FFF7AC 0%, rgba(255, 249, 191, 0) 100%); */
  background: linear-gradient(180deg, #c0d7ff 0%, rgba(240, 245, 254, 0) 100%);
}

.home-decor-by-cate-sec-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.hdbc-title {
  color: black;
  font-size: 1rem;
  /* margin-bottom: 8px; */
  /* margin-top: 10px; */
  text-align: center;
}

.home-explore-more-btn {
  background-color: var(--color-white);
  font-size: 0.825rem;
  color: var(--color-blue);
  font-weight: 600;
  cursor: pointer;
}
.home-explore-more-btn svg {
  position: relative;
  top: 1px;
}

.home-decor-by-cate-sec-head-sub-head {
  display: flex;
  /* gap: .8rem; */
  /* justify-content: space-around; */
  align-items: center;
  gap: 1rem;
}

.home-decor-by-cate-sec-head-h4 {
  background-color: white;
  color: var(--text-color-grey);
  border-width: 1px;
  border-radius: 20px;
  padding: 6px 15px;
  border-color: #d9d9d9;
  border-style: solid;
  display: flex;
  font-size: 0.825rem;
  cursor: pointer;
  white-space: nowrap;
}

.hdbc-active-category {
  background-color: var(--color-blue);
  color: white;
  border-color: var(--color-blue);
}

.home-decor-by-cate-sec-main {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding-inline: 1px;
  margin-top: 9px;
}
.home-decor-by-cate-sec-main::-webkit-scrollbar {
  display: none;
}
.home-decor-by-cate-sec-main-item {
  min-width: 17rem;
  width: 17rem;
  position: relative;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
  margin-bottom: 0.5rem;
  border-radius: 7px;
  overflow: hidden;
}
.home-decor-by-cate-sec-main-item:hover {
  text-decoration: none;
}
.home-decor-by-cate-sec-item-img {
  width: 100%;
  aspect-ratio: 1/1;
}
.home-decor-by-cate-sec-item-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.1rem;
  padding-inline: 0.6rem;
}
.hdbc-item-details {
  display: flex;
  gap: 0.5rem;
  color: rgb(44, 44, 44);
  font-size: 0.82rem;
}
.home-decor-by-cate-sec-item-h4 {
  color: var(--text-color-grey);
  font-size: 1em;
}
.hdbc-item-title {
  color: rgb(44, 44, 44);
  font-size: 0.925rem;
  font-weight: 600;
  /* margin-bottom: .3rem; */
  margin-bottom: 0px;
}

.home-decor-by-cate-sec-price {
  text-decoration: line-through;
  color: var(--text-color-grey);
}
.home-decor-by-cate-sec-discount {
  color: var(--color-green);
  font-weight: 600;
}

.home-viewed-recent-sec-slide-btn {
  font-size: 1.5rem;
  color: black;
  background-color: rgb(255, 255, 255);
  position: absolute;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 45%;
  z-index: 1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.411);
}
.right-slide-btn {
  right: 0;
}

/* home whatsapp sec */

.ebo-wtsp-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  /* border-radius: 15px; */
  /* border-width: 1px; */
  border-style: solid;
  border-color: #d9d9d9;
  width: 95%;
  background-color: white;
}

.ebo-wtsp-sub-sec {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  margin: 8px 0px;
  justify-content: space-between;
}

.ebo-wtsp-logo {
  width: 45px;
}

.ebo-wtsp-msg-container {
  display: flex;
  align-items: center;
}

.ebo-wtsp-sec-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
  color: #323232;
  margin-bottom: 10px;
  margin-top: 5px;
}

.ebo-wtsp-msg {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  color: #323232;
  margin-left: 10px;
}

.ebo-wtsp-arrow {
  width: 35px;
  cursor: pointer;
}

/* home offer banner sec */
.home-offer-banner-sec {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #d4d4d4; */
  margin: 0px;
}
.home-offer-banner-sec-banner {
  width: 95%;
  padding: 2px;
  border-radius: 8px;
}
.home-poster-sec-banner {
  max-height: 100% !important;
  width: 95%;
}

.home-discount-offer-main {
  display: flex;
  overflow: scroll;
  gap: 0.7rem;
  margin-top: 1rem;
  scroll-behavior: smooth;
  /* position:relative; */
}
.home-discount-offer-main::-webkit-scrollbar {
  display: none;
}
.home-discount-offer-main-i {
  width: 20rem;
  border-radius: 10px;
}
.home-discount-offer-sec {
  margin-top: 0px;
}
.home-offer-banner-hr {
  margin: 0px;
}
/* home footer section */
.home-footer-sec {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(6, 1fr);
  padding-bottom: 25px;
}

.home-footer-sec-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-footer-ebo-assured-logo {
  width: 8rem;
}
.home-footer-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-footer-cont-i {
  width: 5rem;
}
.home-footer-cont-p {
  font-size: var(--text-size-small);
  font-weight: 900;
  color: var(--text-color-grey);
  width: 5rem;
  text-align: center;
}

/* Pop up modal */

body.ebo-home-catagory-active-modal {
  overflow-y: hidden;
}

.ebo-home-catagory-btn-modal {
  display: block;
  font-size: 18px;
}

.ebo-home-catagory-modal, 
.ebo-home-catagory-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 50;
}

.ebo-home-catagory-overlay {
  background: rgba(49, 49, 49, 0.8);
  backdrop-filter: blur(8px);
}
.ebo-home-catagory-modal-content {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 15px;
  max-width: 600px;
  min-width: 300px;
  z-index: 51;
}

.ebo-home-catagory-close-modal {
  position: absolute;
  top: 74%;
  right: 36%;
  padding: 5px 7px;
  font-size: 26px;
  font-weight: 500;
  color: white;
  padding: 10px 20px;
  border-radius: 30px !important;
}

.home-offer-banner-small {
  display: none;
}

@media screen and (max-width: 768px) {
  .home-occasion-sec-cont1 {
    display: none;
  }
  .home-pamplet-img {
    aspect-ratio: 1.9/1;
  }
  .home-occasion-sec {
    grid-template-columns: 1fr 1fr;
  }
  .home-pamplet-cont {
    margin-bottom: -1rem;
  }
  .home-decor-by-cate-sec-main-item {
    min-width: 43vw;
  }
  .home-discount-offer-main-i {
    /* width: 7.5rem; */
    border-radius: 10px;
    /* height: 8rem; */
    min-width: 33vw;
    width: 33vw;
  }
  .home-discount-offer-main {
    gap: 1rem;
    margin-top: 1rem;
  }
  .home-offer-banner-sec {
    width: 100%;
  }
  .home-footer-sec {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
    width: 100%;
  }
  /* .home-footer-cont {
    width: 50%;
  } */
  .home-footer-cont-i {
    width: 3.5rem;
  }
  .home-footer-sec-logo {
    height: 3rem;
  }
  .pamplet-slide-btn {
    display: none;
  }
  .slide-btn {
    display: none;
  }
  .home-categries-cont-main {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 0.3rem;
    column-gap: 0.3rem;
  }
  .hdbc-item-title {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .home-decor-by-cate-sec-main {
    gap: 0.5rem;
  }
  .home-viewed-recent-sec-slide-btn {
    display: none;
  }
  .ebo-wtsp-logo {
    width: 34px;
  }
  .ebo-wtsp-arrow {
    width: 24px;
  }
  .ebo-wtsp-msg {
    font-size: 15px;
  }
  .home-pamplet-slide-box {
    display: none;
  }
  .home-viewed-recent-sec-main-item {
    height: 5rem;
    min-width: 16rem;
  }
  .home-viewed-recent-sec-item-i {
    height: 5rem;
    width: 4.5rem;
  }
  .home-viewed-recent-sec-main-item-detail {
    padding: 6px;
  }
  .home-viewed-recent-sec-main-item-detail-h4 {
    font-size: 12px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .home-viewed-recent-sec-main-item-pricef {
    font-size: 12px;
  }
  .home-viewed-recent-sec-main-item-price {
    font-size: 12px;
  }
  .home-viewed-recent-sec-main-item-discount {
    font-size: 12px;
  }
  .home-viewed-recent-sec-main-item-sub-detail {
    margin-top: 0px;
    padding-top: 0px;
  }
  .hdbc-title {
    font-size: 1rem;
  }
  .ebo-wtsp-sec-heading {
    font-size: 1rem;
  }
  .ebo-home-catagory-modal-content {
    padding: 12px 12px;
  }
  .home-offer-banner-small {
    /* display: block; */
    display: flex;
    justify-content: center;
  }
  .home-offer-banner-large {
    display: none;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 3px solid #979797;
  margin-right: 13px;
  background-color: transparent; /* Replace with desired background color */
}

/* Change color of tick mark */
input[type="checkbox"]:checked {
  background-color: transparent; /* Replace with desired background color */
}

input[type="checkbox"]:checked::before {
  content: "\2713";
  display: block;
  text-align: center;
  line-height: 15px;
  font-weight: bolder;
  color: #979797; /* Replace with desired tick mark color */
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .home-pamplet-slide-box {
    display: none;
  }
  .home-occasion-sec {
    grid-template-columns: 1fr 2fr 2fr;
  }
  .home-occasion-sec-cont1-p {
    font-size: var(--text-size-large);
    width: 10rem;
  }
  .home-footer-sec {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }
  .home-footer-sec-logo {
    height: 3rem;
  }
  .home-footer-cont-i {
    width: 4rem;
  }
  .home-categries-cont-main {
    grid-template-columns: repeat(4, 1fr);
  }
  .home-viewed-recent-sec-slide-btn {
    display: none;
  }
  .ebo-wtsp-logo {
    width: 38px;
  }
  .ebo-wtsp-arrow {
    width: 28px;
  }
  .ebo-wtsp-msg {
    font-size: 17px;
  }
}

.speak-to-us-container,
.speak-to-us-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 50;
}

.speak-to-us-overlay {
  background: rgba(49, 49, 49, 0.8);
}

.speak-to-us-modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  line-height: 1.4;
  max-width: 600px;
  min-width: 300px;
  min-height: 15vh;
  z-index: 51;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.speak-to-us-modal-close-button {
  position: absolute;
  top: 5%;
  left: 86%;
  background-color: #ffffff;
  z-index: 55;
  border-radius: 18px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.speak-to-us-content {
  padding: 0px 20px;
  background-color: #ffffff;
}

.speak-to-us-modal-heading {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.speak-to-us-phone-number {
  color: #2136d4;
  font-size: 16px;
}

/* @media screen and (max-width: 767px) {
  .speak-to-us-content {
    width: 100vw;
  }
  .speak-to-us-modal-content {
    top: 48%;
    min-height: 52vh;
  }
  .speak-to-us-modal-close-button {
    top: 41%;
  }
} */
