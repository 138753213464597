.datepicker.android .datepicker-navbar-btn:nth-child(2),
.datepicker.android-dark .datepicker-navbar-btn:nth-child(2) {
  display: none;
}

.datepicker.android .datepicker-navbar-btn,
.datepicker.android-dark .datepicker-navbar-btn {
  background: #2136d4;
  color: white;
  border-radius: 10px;
  margin: 0.5rem;
}

.datepicker.android .datepicker-navbar,
.datepicker.android-dark .datepicker-navbar {
  border: none;
}

.datepicker {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: static;
  width: 100vw;
}
.datepicker.android .datepicker-header {
  color: #2136d4;
  border-color: #2136d4;
}
.datepicker.android .datepicker-wheel {
  border-color: #2136d4;
  border: none;
  background-color: #f0f5fe;
  padding-inline: 2rem;
}
.datepicker.android .datepicker-col-1 {
  margin: 0 0;
}

.datepicker-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media screen and (width > 769px) {
  .datepicker-modal {
    align-items: center;
  }

  .datepicker {
    position: static;
    width: 25rem;
    max-width: 100vw;
    border-radius: 10px;
  }
}
