@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Carter+One&display=swap');
:root {
  --color-white: #ffffff;
  --color-green: #00a807;
  --text-color-grey: #565050;
  --text-size-ex-small: 0.6rem;
  --text-size-small: 0.8rem;
  --text-size-medium: 1rem;
  --text-size-medium2: 1.2rem;
  --text-size-large: 1.5rem;
  --text-size-ex-large: 2rem;
  --color-blue: #2136d4;
  --color-on-hover: #013ab6;
  --font-family-style: "Roboto", sans-serif;
  --font-family-inter: "Inter", sans-serif;
  --logo-width: 5rem;
  --search-container-width: 31.44rem;
  --search-container-height: 32rem;
  --category-logo-width: 4rem;
  --category-logo-height: 4rem;
}

* {
  margin: 0;
  padding: 0;
}
/* in order to change the css of open money */

.details {
  display: none;
}

/* some pre defined css */

.scroll-bar-remove::-webkit-scrollbar {
  display: none;
}

.ebo_skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* loader/spinner css start */
.loader {
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #2136d4;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}






/* HTML: <div class="loader"></div> */
.loader1 {
  width: 30px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
    linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader1::before,
.loader1::after {
   content: "";
   grid-area: 1/1;
   border-radius: 50%;
   background: inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}
.loader1::after {
   opacity: 0.83;
   transform: rotate(60deg);
}
@keyframes l23 {
  100% {transform: rotate(1turn)}
}


/* loader/spinner css  end */

/* mirror animation */
/* define the widht where you want to use */
.mirror-Animation {
  position: relative;
  overflow: hidden;
}
.mirror-Animation::before {
  content: "";
  position: absolute;
  rotate: 50deg;
  height: 500px;
  bottom: -200px;
  background: linear-gradient(90deg, #f0f5fe00 0%, #f0f5fecc 100%);
  opacity: 0.4;
  animation: mirror-animation 3.5s linear infinite;
}

@keyframes mirror-animation {
  0% {
    left: -100%;
  }
  30% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  --swiper-navigation-size: 1.1rem;
  --swiper-navigation-top-offset: 43%;
  --swiper-navigation-sides-offset: 7px;
  --swiper-navigation-color: #000;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 5px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

/* problems */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
  vertical-align: baseline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: normal;
}
input {
  line-height: normal;
}
input:focus {
  outline: none;
}
p + p {
  margin-top: 0;
}
a:hover {
  text-decoration: none;
  color: #565050;
}
a {
  color: #565050;
}
/* nav design just for test purpose  */

.navbar-i {
  display: grid;
  grid-template-columns: 0.5fr 1fr 3fr 1fr;
  border-bottom: 2px solid rgba(97, 97, 97, 0.185);
  background-color: white;
  font-family: var(--font-family-style);
  position: fixed;
  padding-inline: 0.5rem;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
}

.nav-side-components {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: 0.5rem;
}

.nav-side-components-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  margin-right: 1rem;
  justify-content: flex-end;
}

/* nav  */

.search-container {
  position: absolute;
  top: 4rem;
  background-color: var(--color-white);
  transition: 0.3s all;
  height: 0rem;
  width: 79%;
  font-family: var(--font-family-style);
  border-radius: 0.63rem;
  overflow: hidden;
  z-index: 3;
  padding: 0.5rem 0.31rem;
}

/* search result drop down css */

.sr-res {
  display: flex;
  flex-direction: column;
  padding-inline: 0.4rem;
}
.sr-res-h3 {
  padding: 0.4rem;
  font-size: var(--text-size-medium);
  color: var(--text-color-grey);
}

.sr-res-data-offer {
  font-size: var(--text-size-small);
  color: var(--color-blue);
}

/* search result drop down css  end */
.nav-p-dropdown-content {
  display: none;
}
.nav-profile:hover .nav-p-dropdown-content {
  display: block;
}

/* addressPopUp css start */

.nav-address-container-design {
  clip-path: polygon(0% 100%, 100% 100%, 100% 3%, 11% 3%, 8% 0%, 5% 3%, 0 3%);
  background-color: var(--color-white);
  position: relative;
  width: 100%;
  height: 32rem;
}
.nav-address-container {
  position: absolute;

  box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.445);
  width: 100%;
  border-radius: 10px;
  padding-block: 0.5rem;
  display: flex;
  flex-direction: column;
  padding-inline: 1rem;
}

.nav-add-curr-add-box {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(143, 143, 143, 0.603);
  border-radius: 5px;
  width: 5rem;
  height: 5.5rem;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0.5rem;
}

.nav-address-add-address {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7rem;
  background-color: white;
  border: none;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.212);
  margin-top: 2rem;
  gap: 1rem;
  width: 90%;
  align-self: center;
  border-radius: 10px;
  color: var(--color-blue);
}

.nav-address-add-address-p {
  font-size: var(--text-size-medium2);
  display: flex;
  gap: 0.5rem;
}
.nav-address-add-address-p svg {
  position: relative;
  font-size: var(--text-size-large);
  top: 2px;
}
.nav-address-add-address-p {
  font-size: var(--text-size-medium2);
}

.nav-saved-add-sec-header {
  display: flex;
  justify-content: space-between;
}
.nav-saved-add-sec-header-h4 {
  font-size: var(--text-size-medium2);
  color: var(--text-color-grey);
}
.nav-saved-add-sec-header-btn {
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.39);
  cursor: pointer;
  background-color: var(--color-white);
  color: var(--color-blue);
  font-weight: bold;
}

.nav-saved-add-sec-header-btn:hover {
  text-decoration: none;
  background-color: var(--color-blue);
  color: var(--color-white);
}

.nav-saved-add-item {
  display: flex;
  margin-block: 1rem;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 15px;
  box-shadow: 0 0 3px rgba(109, 109, 109, 0.603);
}
.nav-saved-add-item-active {
  display: flex;
  margin-block: 1rem;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 15px;
  box-shadow: 0 0 3px rgba(109, 109, 109, 0.603);
  background-color: rgba(240, 245, 254, 1);
}

/* addressPopUp css end */

/* search categories container design end  */

.search-container-down-animate {
  height: var(--search-container-height);
}

/*for offer icon */
.animate-offer-icon {
  animation: offer-icon-animation 1s infinite normal;
}

@keyframes offer-icon-animation {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  :root {
    --search-container-width: 22rem;
  }
  .search-container {
    width: 85%;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --search-container-width: 100vw;
    --category-logo-width: 3rem;
    --search-container-height: 100vh;
  }

  .search-container {
    width: 95%;
    top: 4rem;
  }
}
