.text-vibrate {
  animation: text-vibrate 0.2s ease-in-out infinite;
}

@keyframes text-vibrate {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
